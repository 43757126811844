import $ from 'jquery';
import QRCode from 'qrcode';

export function DrawQRCode(qrContainter, link) {
    if (qrContainter !== null) {
        console.log(qrContainter);
        console.log(link);
        //new QRCode(qrContainter, link);
        QRCode.toCanvas(qrContainter, link);
    }
}

export function CompleteTotpRegistration(totp, completionUrl, completedUrl) {
    $.ajax({
        url: completionUrl,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({ TOTP: totp }),
        success: function () {
            window.location.href = completedUrl;
        },
        error: function (jqXHR) {
            let warningElement = $("#opterrorMessageIncorrect");
            if (jqXHR.status === 401) {
                warningElement.hide();
                warningElement = $("#opterrorMessageFailed");
            }
            warningElement.show();
        }
    });
}