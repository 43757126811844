import { SortLiteApps } from './pages/dashboard';

(function (window) {
    let LiteDashboard = {
        isAuthenticated: true,
        accessToken: '',
        iconSelector: '',
        chatToken: '',
        apiURI: '',
        init: init
    };

    function init(selector, iconSelector) {        
        LiteDashboard.iconSelector = iconSelector;

        $.get("/litedashboard")
            .then(function (response) {
                $(selector).replaceWith($(response).filter("#liteDashboard").html());
                $(selector).toggle();

                // Save lite dashboard content to localstorage
                window.localStorage.setItem('lite-dashboard', response);

                // Add click handler for icon selector
                var isOpenDashboard = false;
                $(iconSelector).click(function () {
                    $(selector).toggle();
                    if ($(selector).is(':hidden')) {
                        isOpenDashboard = false;
                    } else {
                        isOpenDashboard = true;
                    }
                });

                $(document).click(function (e) {
                    var container = $(iconSelector);
                    if (!container.is(e.target) && container.has(e.target).length === 0) {
                        if (!container.is(':hidden') && $(e.target).parents(".menu-lite-dashboard").length === 0) {
                            if (isOpenDashboard) {
                                $(selector).toggle();
                                isOpenDashboard = false;
                            }
                        }
                    }
                });

                if (typeof userAppOrder !== 'undefined' && userAppOrder == 2 && document.querySelectorAll('[data-app-name="tenant_app_rows_lite"]').length > 0 && window.localStorage.getItem('ls.recent-apps')) {
                    SortLiteApps(2);
                }
            });
            
    }    

    // Export to window object
    window.LiteDashboard = LiteDashboard;
})(window);
