﻿import $ from 'jquery';

export function AcceptEULA() {

    $('#cancel_terms').click(function (e) {
        $("#divAcceptTC").show();
    });

    $("#accept_terms").click(function (e) {
        $("#divAcceptTC").hide();
        DoAcceptTermsAndConsitionPost(e, true);
    });

}

function DoAcceptTermsAndConsitionPost(e, isAccepted) {
    e.preventDefault();

    var url = $("#challengeResponseUrl").val();

    $.ajax({
        url: url,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({ Accepted: isAccepted }),
        success: function (data) {
            window.location.href = data;
        },
        error: function (jqXHR) {
            let warningElement = $(".tacError");
            $("#eulaContainer").remove();
            warningElement.removeClass("hidden");
            warningElement.html(jqXHR.data);
        }
    });
}