﻿import $ from 'jquery';

$(document).ready(function () {

    $("#divFeedbackRate").on("mouseout", function (e) {
        RateSelected();
    });

    $(".feedback-rate").on("click", function (e) {
        var val = parseInt($(this).attr("data-value"));
        Rate(val);
    });

    $(".feedback-rate").on("mouseover", function (e) {
        var val = parseInt($(this).attr("data-value"));
        RateOver(val);
    });

    $(".feedback-rate").on("mouseout", function (e) {
        var val = parseInt($(this).attr("data-value"));
        RateOut(val);
    });

    $("#btnFeedbackSubmit").on("click", function (e) {
        e.preventDefault();

        var $Rating = $("#Rating");
        var $Comment = $("#Comment");
        var $msgFeedback = $("#msgFeedback");

        var rating = parseInt($Rating.val());
        var comment = $Comment.val();

        if (rating === 0) {
            $msgFeedback.text('Please select rating');
            return;
        }

        if (comment === '') {
            $msgFeedback.text('Please enter feedback');
            return;
        }

        var form = $("#FeedbackForm");

        var data = {
            Rating: parseInt($Rating.val()),
            Comment: $Comment.val()
        };

        $.ajax({
            url: form.attr('action'),
            type: form.attr('method'),
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify(data),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (data) {
                UnSelect(0);
                $("#Comment").val('');
                $msgFeedback.text('');
                $("#feedbackResult").hide();
                $('#modalFeedback').modal('hide');
            },
            error: function (jqXHR) {
                UnSelect(0);
                $("#Comment").val('');
                $msgFeedback.text('');
                $("#feedbackResult").hide();
                $('#modalFeedback').modal('hide');
            }
        });
    });
});


function Rate(r) {
    $("#Rating").val(r);
    for (var i = 1; i <= r; i++) {
        $("#Rate" + i).find("i").attr('class', 'fas fa-star');
    }

    UnSelect(r);
}

function RateOver(r) {
    for (var i = 1; i <= r; i++) {
        $("#Rate" + i).find("i").attr('class', 'fas fa-star');
    }

    SetFeedbackResult(r, false);
    UnSelect(r);
}

function RateOut(r) {
    for (var i = 1; i <= r; i++) {
        $("#Rate" + i).find("i").attr('class', 'far fa-star');
    }

    SetFeedbackResult(r, true);
    UnSelect(r);
}

function RateSelected() {
    var setRating = parseInt($("#Rating").val());
    for (var i = 1; i <= setRating; i++) {
        $("#Rate" + i).find("i").attr('class', 'fas fa-star');
    }

    SetFeedbackResult(setRating, true);
}

function UnSelect(r) {
    // unselect remaining
    for (var j = r + 1; j <= 10; j++) {
        $("#Rate" + j).find("i").attr('class', 'far fa-star');
    }
}

function SetFeedbackResult(r, isOut) {
    var $result = $("#feedbackResult");
    var rating = parseInt($("#Rating").val());

    if (isOut) {
        if (rating === 0) {
            $result.hide();
            return;
        }
    }

    $result.show();
    $result.text(" " + r + "/10 ");

    if (r <= 2)
        $result.removeClass("bg-info bg-success").addClass("bg-warning");
    else if (r >= 3 && r <= 6)
        $result.removeClass("bg-warning bg-success").addClass("bg-info");
    else
        $result.removeClass("bg-warning bg-info").addClass("bg-success");
}