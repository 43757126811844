import intlTelInput from 'intl-tel-input';

$(document).ready(function () {    
    startOTPValidityTimer();
    $("#btnResendSMSOTP").on('click', function () {
        if (ipromise) {
            return false;
        }
        var lblSuccess = $(this).next();
        lblSuccess.hide();

        $.ajax({
            url: '/Register/OTP/Resend',
            type: "POST",
            processData: false,
            contentType: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (response) {
                lblSuccess.show();
                startOTPValidityTimer();
            },
            error: function (er) {
                if (er && er.responseText === "ThirtySecondLimit") {
                    $("#TimierLimit").show();
                }
                if (er && er.responseText === "MaxResendLimitReached") {
                    $("#opterrorMaxResendAttempt").show();
                    setTimeout(function () {
                        window.location.href = '/api/sysadmin/user/logout';
                    }, 2000);
                }
            }

        });
    });

    $("#btnResendEmailOTP").on('click', function () {
        if (ipromise) {
            return false;
        }
        var lblSuccess = $(this).next();
        lblSuccess.hide();
        $.ajax({
            url: '/Register/EmailOTP/Resend',
            type: "POST",
            processData: false,
            contentType: false,
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (response) {
                lblSuccess.show();
                startOTPValidityTimer();
            },
            error: function (er) {
                if (er && er.responseText === "ThirtySecondLimit") {
                    $("#TimierLimit").show();
                }
                if (er && er.responseText === "MaxResendLimitReached") {
                    $("#opterrorMaxResendAttempt").show();
                    setTimeout(function () {
                        window.location.href = '/api/sysadmin/user/logout';
                    }, 2000);                    
                }
            }

        });
    });

    const input = document.querySelector('#js-otp-prompt-phonenumber');
    if (!input) return;

    const iti = intlTelInput(input, {
        separateDialCode: true,
        utilsScript: "intl-tel-input/build/js/utils.js"
    });

    const hidden = document.querySelector("#full_phonenumber");

    $("#UserRegistrationForm").submit(function (event) {
        hidden.value = iti.getNumber();
    });   

    $("#btnVerifyCode").on('click', function (event) {
        event.preventDefault();

        $("#divPromptErr").hide();

        var appendURL = "";
        
        if (window.location.href.toLowerCase().indexOf("/yauth2/identity") > -1) {            
            appendURL = "/yAuth2/identity";            
        }
        
        $.ajax({
            url: appendURL + '/Register/OTP/Prompt',
            type: "POST",
            contentType: "application/json",
            data: JSON.stringify({ PhoneNumber: iti.getNumber() }),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function (res) {
                if (res !== "Invalid State")
                {
                    window.location.href = appendURL + "/Register/OTP/Verify";
                }
            },
            error: function (res) {
                if (res) {
                    if (res.responseText) {
                        var errorMsg = "You cannot use the same phone number for Text (SMS) multi-factor authentication that you use to reset your password. Please try a different number or different multi-factor option.";
                        if (res.responseText.indexOf("Y1 Error: Invalid Phone number") > -1) {
                            $("#divPromptErr").text(errorMsg);
                            $("#divPromptErr").show();
                        }
                        else {
                            $("#divPromptErr").text(res.responseText);
                            $("#divPromptErr").show();
                        }
                    }
                }
            }
        });
    });
     
});

let timer = 30;
let ipromise = undefined;
function startOTPValidityTimer() {
    if (document.getElementById("btnResendSMSOTP")) {
        $("#btnResendSMSOTP").attr('disabled', true);
    }

    if (document.getElementById("btnResendEmailOTP")) {
        $("#btnResendEmailOTP").attr('disabled', true);
    }

    timer = 30;
    if (ipromise) {
        clearInterval(ipromise);
        ipromise = undefined;        
    }

    ipromise = setInterval(function () {
        var seconds;
        seconds = parseInt(timer % 60, 10);
        let calSeconds = seconds < 10 ? "0" + seconds : seconds;
        $("#timerSeconds").text(calSeconds);
        $("#resendTimer").show();
        if (timer > 0) {
            timer--;
        } else {
            clearInterval(ipromise);
            ipromise = undefined;
            $("#resendTimer").hide();
            $("#TimierLimit").hide();
            if (document.getElementById("btnResendSMSOTP")) {
                $("#btnResendSMSOTP").removeAttr('disabled');                
            }
            if (document.getElementById("btnResendEmailOTP")) {
                $("#btnResendEmailOTP").removeAttr('disabled');
            }            
        }
    }, 1000);
}