﻿import $ from 'jquery';

function getAnnouncements() {
    $.ajax({
        url: "/announcement",
        type: "GET",
        contentType: "application/json",
        success: function (data) {
            if (data && data.length) {
                bindAnnouncements(data);
            } else {
                $("#notification-status").hide();
            }
        },
        error: function (jqXHR) {

        }
    });
}
getAnnouncements();

////Check for announcements every 5 Minutes
//setInterval(function () {
//    getAnnouncements();
//}, 300000);

function bindAnnouncements(data) {
    var announcementCount = data.length;

    var html = '<div class="w-100 announcement-block alert alert-primary fade in alert-dismissible show">';         
        html += '<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>';         
        html += '<span"> <i class="fal fa-bell pe-3" aria-hidden="true"></i>&nbsp;' + announcementCount + '&nbsp;items need your attention</h4> ';
        html +='</div>'  

    $("div#announcement").html(html);
    $("#announcement-count-block").html(announcementCount);

    var announcementList = '';
    for (var i = 0; i <= data.length - 1; i++) {
        var bBorder = i === data.length - 1 ? "" : "border-bottom"; 
        announcementList += '<li class="w-100 mb-3 ' + bBorder +' pb-3">';
        announcementList += '<h3 class="h4 mb-2 text-truncate w-100">' + data[i].Name + '</h4>'; 
        announcementList += getMessage(data[i]); 
        announcementList += '</li>';
    }
    $("#notification-status").html(announcementCount).show();
    $("#announcement-list").html(announcementList);

}

var remainingMessages = [];

function getMessage(data) {
    //look for separator '||' to break message and show more... link
    if (data.Message.indexOf('||') > -1)
    {
        var msg = data.Message.substring(0, data.Message.indexOf('||'));
        msg = msg + '<a href="javascript:void(0)" class="more" data-id="' + data.Id + '" data-isCA="' + data.IsClientAnnouncement + '">more...</a>';

        var remainingMsg = data.Message.substring(data.Message.indexOf('||') + 2);
        remainingMessages.push({ Id: data.Id, Message: remainingMsg, IsClientAnnouncement: data.IsClientAnnouncement.toString() });
        return msg;
    }
    return data.Message;
}