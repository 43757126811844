import $ from 'jquery';

export function VerifyOTPCode(hotp, verificationUrl, completedUrl, errorUrl) {
    $.ajax({
        url: verificationUrl,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({ hotp: hotp }),
        success: function () {
            window.location.href = completedUrl;
        },
        error: function (jqXHR) {
            let warningElement = $("#opterrorMessageIncorrect");
            if (jqXHR.status === 401) {
                warningElement.hide();
                warningElement = $("#opterrorMessageFailed");
            }
            warningElement.show();
        }
    });
}