import $ from 'jquery';

export function handleFailedChallengeResponse(statusCode) {
    if (statusCode === 400 || statusCode === 404) {
        authenticationBadRequestFailed();
    }

    if (statusCode === 401) {
        authenticationAuthFailed();
    }
}
function authenticationAuthFailed() {
    hideAllFailureSections();
    $(authenticationFailedElement()).show();
}

function authenticationBadRequestFailed() {
    hideAllFailureSections();
    $(badRequestElement()).show();
}

function hideAllFailureSections() {
    $(badRequestElement()).hide();
    $(authenticationFailedElement).hide();
}

const isJsonString = function (str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

var badRequestElement = function () { return document.getElementById('badRequestError'); };
var authenticationFailedElement = function () { return document.getElementById('authenticationFailedError'); };

//let badRequestElement = () => document.getElementById('badRequestError');
//let authenticationFailedElement = () => document.getElementById('authenticationFailedError');

export function verifyCode(verificationCode, provider, challengeUrl, rememberUser) {
    $("#ResendCodeMsg").hide();

    $.ajax({
        url: challengeUrl,
        type: "POST",
        contentType: "application/json",
        data: JSON.stringify({ Provider: provider, ResponseCode: verificationCode, RememberUser: rememberUser }),
        success: function (response) {
            window.location.href = response;
        },
        error: function (jqXHR) {
            let verifyBtn = document.getElementById('verificationSubmit');

            if (verifyBtn != null) {
                verifyBtn.disabled = false;
            }
            if (jqXHR && jqXHR.responseText && isJsonString(jqXHR.responseText)) {
                let resText = JSON.parse(jqXHR.responseText);               
                if (resText && resText.ErrorMessage === "Locked Out") {
                    $("#opterrorMaxVerifyAttempt").show();
                    hideAllFailureSections();
                    setTimeout(function () {
                        window.location.href = '/account/login?returnurl=' + resText.ReturnURL;
                    }, 2000);
                    return;
                }               
            }

            handleFailedChallengeResponse(jqXHR.status);
        }
    })
}