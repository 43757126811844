﻿import $ from "jquery";
import supportedAlgorithms from './fidoSupport';
import handleFailedChallengeResponse from './AuththenticationHandlers';

export function createFidoRegistration(
  base64Challenge,
  relyingPartyId,
  userHandle,
  username,
  completionUrl,
  completedUrl,
  errorUrl
) {
  const challenge = convertChallengeToByteArray(base64Challenge);
  const replyingParty = createRelyingParty(relyingPartyId);
  const fidoUser = createUserHandle(userHandle, username);

  // Supported algorithms, ordered by preference
  const pubKeyCredParams = supportedAlgorithms();

  navigator.credentials.create({
    publicKey: {
      challenge: challenge,
      rp: replyingParty,
      user: fidoUser,
      pubKeyCredParams: pubKeyCredParams
    }
  })
      .then(function(credentials) {
      // base64 encode array buffers
      sendFidoCredentialsToServer(credentials, completionUrl, completedUrl, errorUrl);
    })
      .catch(function(error) {
      console.error(error);
      window.location.href = errorUrl + '?message=' + error;
    });
}

function sendFidoCredentialsToServer(credentials, completionUrl, completedUrl, errorUrl) {
  let encodedCredentials = encodeCredentials(credentials)
  // post to register callback endpoint and redirect to homepage
  sendEncodedCredentialsToServer(encodedCredentials, completionUrl, completedUrl, errorUrl);
}

function encodeCredentials(credentials) {
  return {
    id: credentials.id,
    rawId: btoa(String.fromCharCode.apply(null, new Uint8Array(credentials.rawId))),
    type: credentials.type,
    response: {
      attestationObject: btoa(String.fromCharCode.apply(null, new Uint8Array(credentials.response.attestationObject))),
      clientDataJSON: btoa(String.fromCharCode.apply(null, new Uint8Array(credentials.response.clientDataJSON)))
    }
  }
}

function sendEncodedCredentialsToServer(encodedCredentials, completionUrl, completedUrl, errorUrl) {
  $.ajax({
    url: completionUrl,
    type: "POST",
    contentType: "application/json",
    data: JSON.stringify(encodedCredentials),
    success: function () {
      window.location.href = completedUrl;
    },
    error: function (jqXHR) {
      window.location.href = errorUrl + '?message=' + jqXHR.responseText;
    }
  });
}

function convertChallengeToByteArray(base64Challenge) {
  let challengeBytesAsString = atob(base64Challenge);
  let challenge = new Uint8Array(challengeBytesAsString.length);
  for (let i = 0; i < challengeBytesAsString.length; i++) {
    challenge[i] = challengeBytesAsString.charCodeAt(i);
  }

  return challenge;
}

function createRelyingParty(relyingPartyId) {
  return {
    id: relyingPartyId,
    name: "Yardi IdentityServer"
  };
}

function createUserHandle(userHandleString, username) {
  let userHandleBytesAsString = atob(userHandleString);
  let userHandle = new Uint8Array(userHandleBytesAsString.length);
  for (let i = 0; i < userHandleBytesAsString.length; i++) {
    userHandle[i] = userHandleBytesAsString.charCodeAt(i);
  }

  return {
    name: username,
    displayName: username,
    id: userHandle
  };
}

export function challengeUserByFido(challengeJson, challengeResponseUrl, errorUrl) {
  hideRetry();

  let fidoChallenge = JSON.parse(challengeJson);

  let challenge = convertChallengeToByteArray(fidoChallenge.Base64Challenge);

  let rpId = fidoChallenge.RelyingPartyId;

  let allowCredentials = createdAllowedCredentials(fidoChallenge.Base64KeyIds);

  navigator.credentials.get({ publicKey: { challenge, rpId, allowCredentials } })
      .then(function (result) {
      // base64 encode array buffers
      let encodedResult = encodeChallengeResult(result);

      let provider = document.getElementById("provider");

      let response = {
        "provider": provider.value,
        "responseCode": JSON.stringify(encodedResult)
      }

      sendChallengeResponse(response, challengeResponseUrl, errorUrl);
    })
      .catch(function (error) {
      console.error(error);
      showRetry();
      //window.location.href = `${errorUrl}?message=${error}`;
    });
}

function showRetry() {
  console.log('show retry');
  $('#retryInformaton').show();
}

function hideRetry() {
  console.log('hide retry');
  $('#retryInformaton').hide();
}

function sendChallengeResponse(response, challengeUrl, errorUrl) {
  $.ajax({
    url: challengeUrl,
    type: 'POST',
    contentType: 'application/json',
    data: JSON.stringify(response),
    success: function (response) {
      window.location.href = response;
    },
    error: function (jqXHR) {
      handleFailedChallengeResponse(jqXHR.status);
    }
  });
}

function encodeChallengeResult(result) {
  return {
    id: result.id,
    rawId: btoa(String.fromCharCode.apply(null, new Uint8Array(result.rawId))),
    type: result.type,
    response: {
      authenticatorData: btoa(String.fromCharCode.apply(null, new Uint8Array(result.response.authenticatorData))),
      signature: btoa(String.fromCharCode.apply(null, new Uint8Array(result.response.signature))),
      userHandle: btoa(String.fromCharCode.apply(null, new Uint8Array(result.response.userHandle))),
      clientDataJSON: btoa(String.fromCharCode.apply(null, new Uint8Array(result.response.clientDataJSON)))
    }
  };
}

function createdAllowedCredentials(base64KeyIds) {
  let keys = base64KeyIds;
  let allowCredentials = [];

  for (let i = 0; i < keys.length; i++) {
    let keyIdBytesAsString = window.atob(keys[i]);

    let key = new Uint8Array(keyIdBytesAsString.length);
    for (let i = 0; i < keyIdBytesAsString.length; i++) {
      key[i] = keyIdBytesAsString.charCodeAt(i);
    }

    allowCredentials.push({
      type: "public-key",
      id: key
    });

  }
  return allowCredentials;
}