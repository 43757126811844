﻿$(document).ready(function () {

    $('[id^=btnRemoveMfa]').click(function () {
        var $this = $(this);
        event.preventDefault();

        bootbox.confirm({
            closeButton: false,
            message: 'Click OK to unregister this multi-factor authentication option.',
            callback: function (result) {
                if (!result)
                    return;

                var i = $this.attr('data-index');
                var fidoKeyDisplayName = "";
                if ($this.attr('data-id') == "FidoMfaProvider") {
                    fidoKeyDisplayName = "&fidoKeyName=" + $this.data('fidodisplayname')
                }
                $.ajax({
                    url: $this.attr('data-url') + "?provider=" + $this.attr('data-id') + fidoKeyDisplayName,
                    type: "DELETE",
                    contentType: "application/json",
                    dataType: "json",
                    data: JSON.stringify({}),
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader("RequestVerificationToken",
                            $('input:hidden[name="__RequestVerificationToken"]').val());
                    },
                    success: function () {
                        if (localStorage) {
                            localStorage.removeItem("ls.user-mfa-check");
                        }
                        window.location = "/MfaRegistration";
                    },
                    error: function (jqXHR) { }
                });
            }
        });
    });

    $('[id^=btnRemoveFidoMfa]').click(function () {
        var $this = $(this);
        event.preventDefault();
        var id = $(this).data('id');
        var url = $(this).data('url');

        $.ajax({

            url: $this.attr('data-url') + "?provider=FidoMfaProvider&fidoKeyName=" + id,
            type: "DELETE",
            contentType: "application/json",
            dataType: "json",
            data: JSON.stringify({}),
            beforeSend: function (xhr) {
                xhr.setRequestHeader("RequestVerificationToken",
                    $('input:hidden[name="__RequestVerificationToken"]').val());
            },
            success: function () {
                if (localStorage) {
                    localStorage.removeItem("ls.user-mfa-check");
                }
                window.location = "/MfaRegistration";
            },
            error: function (jqXHR) { }
        });
    });

    $('[id^=btnMakeDefault]').click(function () {
        var $this = $(this);
        event.preventDefault();

        bootbox.confirm({
            closeButton: false,
            message: 'Are you sure you want to make the selected MFA default?',
            callback: function (result) {
                if (!result)
                    return;

                var i = $this.attr('data-index');
                $.ajax({
                    url: $this.attr('data-url') + "?provider=" + $this.attr('data-id'),
                    type: "POST",
                    contentType: "application/json",
                    dataType: "json",
                    data: JSON.stringify({}),
                    beforeSend: function (xhr) {
                        xhr.setRequestHeader("RequestVerificationToken",
                            $('input:hidden[name="__RequestVerificationToken"]').val());
                    },
                    success: function () {
                        window.location = "/MfaRegistration";
                    },
                    error: function (jqXHR) { }
                });
            }
        });
    });

    if ($("#modalMFARegWelcome").length) {
        $('#modalMFARegWelcome').modal('show');
    }

    if ($("#modalMFAOutage").length) {
        $('#modalMFAOutage').modal('show');
    }

})